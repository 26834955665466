import React, {useEffect} from 'react';
import {Row, Col} from 'antd';
import Layout from '~components/layout';
import Seo from '~components/seo';
import ConsumerAuth from '~containers/authenticate/consumer-auth';
import {firebaseAnalytics} from '~utils/firebase';
import CusBreadcrumbs from '~components/breadcrumbs';

import '../../styles.scss';
import CreateEdit from '~containers/account/address/create-edit';

const CreateAddressPage = props => {
  useEffect(() => {
    firebaseAnalytics.logEvent('CreateAddress_page_visited');
  }, []);

  const title = 'Thêm địa chỉ';

  return (
    <ConsumerAuth>
      <Layout>
        <Seo title={title} />
        <Row justify='center' className='category-path com-bg-gray'>
          <Col xs={24} md={22} lg={21}>
            <CusBreadcrumbs items={['Thông tin tài khoản', 'Địa chỉ nhận hàng', title]} />
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} md={22} lg={21}>
            <div id='account-page'>
              <div className='account-content'>
                <CreateEdit />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </ConsumerAuth>
  );
};

export default CreateAddressPage;
